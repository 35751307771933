import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

// Modules
import auth from "./modules/auth";
import general from "./modules/general";
import liffGroupBuying from "./modules/liff/group-buying";
import liffCoreGroupBuying from "./modules/liff/core-group-buying";
import liffCoupon from "./modules/liff/coupon";
import liffCustomerBind from "./modules/liff/customer-bind";
import liffMemberCard from "./modules/liff/member-card";
import liffHuggies from "./modules/liff/huggies";
import liffChatroom from "./modules/liff/chatroom";
import liffOrder from "./modules/liff/order";
import liffGeneral from "./modules/liffGeneral";
import liffRedeemCode from "./modules/liff/redeem-code";
import liffLoading from "./modules/liff/loading";
import faviconBadge from "./modules/faviconBadge";
import liffAuth from "./modules/liff/v2/auth";
import liffBooking from "./modules/liff/v2/booking";
import liffPoint from "./modules/liff/v2/point";
import liffPreorder from "./modules/liff/v2/preorder";
import liffModule from "./modules/liff/v2/module";
import liffReservation from "./modules/liff/v2/reservation";
import dashboardModule from "./modules/modules";
import route from "./modules/route";

//Proj
import projAbbottAnd from "./modules/liff/proj/abbott-and";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    general,
    liffGroupBuying,
    liffCoreGroupBuying,
    liffCoupon,
    liffCustomerBind,
    liffMemberCard,
    liffHuggies,
    liffChatroom,
    liffOrder,
    liffGeneral,
    liffRedeemCode,
    liffLoading,
    faviconBadge,
    liffAuth,
    liffBooking,
    liffPoint,
    liffPreorder,
    liffModule,
    dashboardModule,
    route,
    projAbbottAnd,
    liffReservation
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      reducer(val) {
        return {
          auth: val.auth,
        };
      },
    }),
  ],
});
