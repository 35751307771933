export default {
  namespaced: true,
  state: {
    isLoading: false,
  },
  actions: {
    showLoading(context) {
      context.state.isLoading = true
    },
    hideLoading(context) {
      context.state.isLoading = false
    },
  },
};
