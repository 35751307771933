import pointApi from "@/apis/liff/v2/point";
import preorderApi from "@/apis/liff/v2/preorder";
import _ from "lodash";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    cart: {}, // key: sku id, value: quantity
    skus: [],
    categories: [],
    configuration: null,
    memberPoints: 0,
  },
  getters: {
    totalQuantity({ cart }) {
      return _.chain(cart).values().sum().value();
    },
    isPreorderMode({ configuration }) {
      return _.get(configuration, 'charge_mode', null) === null;
    },
    isMoneyMode({ configuration }) {
      return _.get(configuration, 'charge_mode', null) === 'money';
    },
    isPointMode({ configuration }) {
      return _.get(configuration, 'charge_mode', null) === 'point';
    },
    shouldDisplayMemberPoints({ configuration }) {
      return _.get(configuration, 'config.point_mode.show_point', false);
    },
  },
  actions: {
    async fetchConfiguration(context, preorderId) {
      context.state.configuration = _.get(await preorderApi.getConfiguration(preorderId), "data.data", []);
    },
    async fetchSkus(context, query) {
      const response = await preorderApi.getSkus(query)
      context.state.skus = _.get(response, "data.data", []);
      context.state.categories = _.get(response, "data.categories", []);
      return response
    },
    async fetchMemberPoints(context) {
      context.state.memberPoints = _.get(await pointApi.getAvailablePoints(null), 'point', 0)
    },
    updateCartQuantity(context, { skuId, quantity }) {
      if (quantity === 0) {
        Vue.delete(context.state.cart, skuId);
      } else {
        Vue.set(context.state.cart, skuId, quantity);
      }
    },
    clearCart(context) {
      Object.keys(context.state.cart).forEach(skuId => {
        Vue.delete(context.state.cart, skuId);
      });
    },
  },
};
